
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { ClassActions } from "@/store/vm/class/enums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { StepResult } from "@/store/captain/CaptainModule";

export default defineComponent({
  name: "student-list",
  components: {},
  props: {
    classId: Number,
  },
  emits: ["insertStudent"],
  data() {
    return {
      loadingModule: true,
      moment: moment,
    };
  },
  setup(props, { emit }) {
    const store = useStore();

    const list = ref([]);

    const count = computed(() => {
      return list.value.length;
    });

    const genderLabels = {
      0: "Nam",
      1: "Nữ",
      2: "Khác",
    };

    onMounted(async () => {
      await apply();
    });

    const apply = async () => {
      const result: StepResult = await store
        .dispatch(ClassActions.GET_LIST_STUDENT, props.classId)
        .catch((e) => {
          console.log(e);
        });

      console.log(result);

      if (result.isSuccess) {
        list.value = result.data as any;
      } else {
        console.error(
          "Xảy ra lỗi trong quá trình lấy danh sách học viên của lớp học"
        );
        console.log(result);
      }
    };

    const onDel = (studentId) => {
      Swal.fire({
        title: "Bạn muốn xóa học viên này khỏi lớp học?",
        text: "Học viên sẽ KHÔNG THỂ truy cập vào các tài nguyên của lớp học cho tới khi được thêm lại vào lớp.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res: StepResult = await actionDelete(studentId);
          console.log("res");
          console.log(res);
          if (res.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: res.data || "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            await apply();
          } else {
            Swal.fire({
              text: res.data || "Error",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        }
      });
    };

    const onBand = (studentId) => {
      Swal.fire({
        title: "Bạn muốn khóa học viên này khỏi lớp học?",
        text: "Học viên sẽ KHÔNG THỂ truy cập vào các tài nguyên của lớp học và KHÔNG THỂ được thêm lại vào lớp.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res: StepResult = await actionBand(studentId);
          console.log("res");
          console.log(res);
          if (res.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: res.data || "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            await apply();
          } else {
            Swal.fire({
              text: res.data || "Error",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        }
      });
    };

    const actionDelete = async (studentId) => {
      const result = await store
        .dispatch(ClassActions.REMOVE_STUDENT_FROM_CLASS, {
          classId: props.classId,
          studentId,
        })
        .catch((e) => {
          console.log(e);
        });
      return result;
    };

    const actionBand = async (studentId) => {
      const result = await store
        .dispatch(ClassActions.BAND_STUDENT_FROM_CLASS, {
          classId: props.classId,
          studentId,
        })
        .catch((e) => {
          console.log(e);
        });
      return result;
    };

    const clickInsert = () => {
      emit("insertStudent");
    };

    const statusLabels = {
      0: "Active",
      1: "Chờ chuyển tiền",
      2: "Chờ admin duyệt",
      3: "Khóa",
    };

    return {
      genderLabels,
      list,
      count,
      onDel,
      onBand,
      apply,
      clickInsert,
      statusLabels,
    };
  },
});
