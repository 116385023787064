
import ListStudentInClass from "@/views/class/student/List.vue";
import InsertStudentToClass from "@/views/class/selector/InsertStudentToClass.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "class-student-index",
  props: {
    classId: Number,
    title: {
      type: String,
      default: () => {
        return "class";
      },
    },
  },
  components: {
    ListStudentInClass,
    InsertStudentToClass,
  },
  setup() {
    const insertStudentToClassRef = ref();
    const listStudentInClassRef = ref();

    const activateInsertClass = () => {
      insertStudentToClassRef.value.activate();
    };

    const onInsertSuccess = () => {
      listStudentInClassRef.value.apply();
    };

    return {
      insertStudentToClassRef,
      listStudentInClassRef,
      activateInsertClass,
      onInsertSuccess,
    };
  },
});
