import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListStudentInClass = _resolveComponent("ListStudentInClass")!
  const _component_InsertStudentToClass = _resolveComponent("InsertStudentToClass")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ListStudentInClass, {
      classId: _ctx.classId,
      onInsertStudent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activateInsertClass())),
      ref: "listStudentInClassRef"
    }, null, 8, ["classId"]),
    _createVNode(_component_InsertStudentToClass, {
      classId: _ctx.classId,
      peopleId: [],
      ref: "insertStudentToClassRef",
      title: _ctx.title,
      onInsertSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInsertSuccess()))
    }, null, 8, ["classId", "title"])
  ], 64))
}