
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ClassActions } from "@/store/vm/class/enums";
import { ClassPublicStatus, ClassStatus } from "@/store/vm/class/ClassModule";

export default defineComponent({
  name: "detail-class",
  props: {
    classIdProp: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const classId = computed(() => {
      return props.classIdProp;
    });

    const hasClassId = computed(() => {
      return props.classIdProp !== 0;
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const defaultData = {
      class_id: 0,
      course_id: 0,
      code: "",
      name: "",
      branch_id: 0,
      start_date: "",
      end_date: "",
      teacher_id: [],
      sub_teacher_id: [],
      is_public: ClassPublicStatus.PUBLIC,
      status: ClassStatus.EXPECTED,
      holiday: [],
      schedule_config: [],
    };

    onMounted(async () => {
      apply();
    });

    watch(classId, () => {
      apply();
    });

    const apply = async () => {
      if (hasClassId.value) {
        const detail = await store
          .dispatch(ClassActions.GET_DETAIL, classId.value)
          .catch((e) => {
            console.log(e);
          });
        formData.value = detail;
      }
    };

    const formData = ref({ ...defaultData });

    const statusLabels = [
      {
        value: 0,
        label: "Lịch dự kiến",
      },
      {
        value: 1,
        label: "Lịch chính thức",
      },
      {
        value: 10,
        label: "Đang học",
      },
      {
        value: 11,
        label: "Đã kết thúc",
      },
    ];

    const isPublicLabels = [
      {
        value: 0,
        label: "Chưa công khai",
      },
      {
        value: 1,
        label: "Công khai (hiển thị trên App)",
      },
    ];

    return {
      defaultData,
      hasClassId,
      formData,
      formRef,
      apply,
      statusLabels,
      isPublicLabels,
      classId,
    };
  },
});
