
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { StepResult } from "@/store/captain/CaptainModule";
import { ClassActions } from "@/store/vm/class/enums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import StudentSelector from "@/views/people/student/selector/Student.vue";
import ClassSelector from "@/views/class/selector/Class.vue";
import ClassDetail from "@/views/class/child/Detail.vue";

export default defineComponent({
  name: "insert-students-to-class",
  components: {
    StudentSelector,
    ClassSelector,
    ClassDetail,
  },
  emits: ["insertSuccess"],
  props: {
    classId: {
      type: Number,
      default: 0,
    },
    peopleId: {
      type: Array,
      default: () => [],
    },
    allowMultiStudent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => {
        return "class"; // Show thêm học viên vào lớp học hay vào khóa học video
      },
    },
  },
  data: function () {
    return {
      btnShowModalId: "btnShowModalIdInsertStudentToClassModal",
      modalId: "modalInsertStudentToClassModal",
    };
  },
  methods: {
    activate: function () {
      document.getElementById(this.btnShowModalId)?.click();
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const classIdTarget = ref(0);
    const peopleIdTarget = ref<number[]>([]);
    const studentSelectorRef = ref();

    const insertStudentToClassModalRef = ref<null | HTMLElement>(null);

    // watch prop
    const hasClassId = computed(() => {
      return props.classId !== 0;
    });

    const hasPeopleId = computed(() => {
      return props.peopleId.length > 0;
    });

    const classId = computed(() => {
      return props.classId;
    });

    const peopleId = computed(() => {
      return props.peopleId;
    });

    onMounted(() => {
      apply();
    });

    watch([classId, peopleId], () => {
      apply();
    });

    const apply = () => {
      classIdTarget.value = props.classId;
      // eslint-disable-next-line
      peopleIdTarget.value = props.peopleId as any;
    };

    const submit = async () => {
      const classId = classIdTarget.value;
      const studentId = studentSelectorRef.value.get();

      const result: StepResult = await store
        .dispatch(ClassActions.ADD_STUDENT_TO_CLASS, {
          classId,
          studentId,
        })
        .catch((e) => {
          console.log(e);
        });

      if (result.isSuccess) {
        emit("insertSuccess");
        Swal.fire({
          position: "top-end",
          text: result.data || "Success!",
          icon: "success",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });

        hideModal(insertStudentToClassModalRef.value);
      } else {
        Swal.fire({
          text: result.data || "Error",
          icon: "error",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    };

    const onChange = (newVal) => {
      classIdTarget.value = newVal.value;
    };

    return {
      insertStudentToClassModalRef,
      submit,
      apply,
      hasClassId,
      hasPeopleId,
      peopleIdTarget,
      classIdTarget,
      onChange,
      studentSelectorRef,
    };
  },
});
